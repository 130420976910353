<template>
	<div class="Logs">

		<!-- 整体数据 -->
		<div class="frame_content mgtop3">
			<el-breadcrumb>
				<el-breadcrumb-item>首页</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">基础数据</router-link>
				</el-breadcrumb-item>
				<el-breadcrumb-item>
					<router-link to="/">协议类别</router-link>
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
		<!-- 整体数据 end-->

		<div class="pdding20 bgffffff overflow mgtop10">
			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">筛选查询</span>
			</div>

			<div class="wf mgtop10">
				<el-form inline class="ant-advanced-search-form">
					<el-form-item>
						<el-button size="small" type="success" icon="plus" @click="newItem"><i
								class="fa fa-plus"></i>新增类别</el-button>
					</el-form-item>
					<el-form-item>
						<el-button size="small" type="warning" icon="plus" @click="newClassification"><i
								class="fa fa-plus"></i>类别分类</el-button>
					</el-form-item>
					<el-form-item label="名称">
						<el-input size="small" placeholder="请填写名称" v-model="searchForm.name"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button size="small" type="primary" icon="search" @click="doSearch"><i
								class="fa fa-search"></i>查询</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>

		<div class="frame_content mgtop10" style="min-height: 600px;">

			<div class="wf lineh40">
				<div class="h20 mgtop10 fl" style="background: #3F85E9;width: 4px;"></div>
				<span class="fl bold mgl10 font14">数据展示</span>
			</div>

			<el-table class="mgtop10" :data="protocolTypeList" :stripe="true" border>
				<el-table-column label="类别名称" prop="name" />
				<el-table-column label="自定义值" prop="selfVal" />
				<el-table-column label="发布时间" prop="subTime" align="center" width="180px" />
				<el-table-column label="操作" key="action" align="center" width="200px">
					<template slot-scope="scope">
						<el-button size="small" type="primary" @click="showType(scope.$index)"><i
								class="fa fa-edit"></i>编辑</el-button>
						<el-button v-if="principal.level==3" size="small" type="danger"
							@click="removeType(scope.$index)"><i class="fa fa-remove"></i>删除</el-button>
					</template>
				</el-table-column>
			</el-table>

			<!-- 分页 -->
			<div class="wf mgtop10">
				<div class="pdding20">
					<el-pagination v-show="protocolTypeList.length>0" background layout="prev, pager, next"
						@current-change="pageChange" :page-size="searchForm.pageSize" :total="searchForm.total"
						class="fr"> </el-pagination>
				</div>
			</div>


		</div>


		<!-- 管理员信息 -->
		<el-dialog :close-on-click-modal="false" title="资讯类别" width="800px" :visible.sync="dialogFormVisible">
			<el-form ref="protocolTypeForm" :model="protocolTypeForm" label-width="120px" :rules="rules">
				<el-row :gutter="24">
					<el-col :span="12">
						<el-form-item label="类别名称" prop="name">
							<el-input v-model="protocolTypeForm.name" placeholder="例如:新闻资讯" />
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="自定义值" prop="selfVal">
							<el-input v-model="protocolTypeForm.selfVal" placeholder="例如:xwzx或新闻资讯" />
						</el-form-item>
					</el-col>
				</el-row>

			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="publishType">保存</el-button>
			</span>
		</el-dialog>
		<!-- 管理员信息 end-->


	</div>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
	export default {
		name: 'fragementBase',
		computed: {
			...mapGetters([
				'principal'
			])
		},
		data() {
			return {
				dialogMask: false,
				dialogzIndex: -1,
				tableLocale: {
					emptyText: '暂无数据'
				},
				dialogFormVisible: false,
				searchForm: {
					page: 1,
					pageSize: 20,
					total: 0
				},
				protocolTypeList: [],
				protocolTypeForm: {},
				rules: {
					name: [{
							required: true,
							message: '请填写类别名称',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 20,
							message: '长度在 1 到 20个字符',
							trigger: 'blur'
						}
					],
					selfVal: [{
							required: true,
							message: '请填写自定义值',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 30,
							message: '长度在 1 到 30个字符',
							trigger: 'blur'
						}
					]
				}
			}
		},
		mounted() {

		},
		methods: {
			newClassification() {
				window.location.href = "/m/db/protocol/classification";
			},
			cancel() {
				this.dialogFormVisible = false
			},
			doSearch() {
				//查询
				this.searchForm.page = 1;
				this.getTypeList();
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.getTypeList();
			},
			newItem() {
				this.dialogFormVisible = true
				this.protocolTypeForm = {};
			},
			getTypeList() {
				this.$http.request(this, this.$apis.material.protocolTypeList, this.searchForm, false, res => {
					this.protocolTypeList = res.data;
					this.searchForm.total = res.count;
				});
			},
			publishType() {
				this.$refs['protocolTypeForm'].validate((valid) => {
					if (valid) {
						var protocolTypeForm = Object.assign({}, this.protocolTypeForm);
						protocolTypeForm.typeSerial = this.curTypeSerial;
						protocolTypeForm.updateTime = "";

						this.$http.request(this, this.$apis.material.protocolTypePublish, protocolTypeForm, true,
							res => {
								let data = res.data;
								if (data != null) {
									this.protocolTypeList.push(data);
								}
								this.dialogFormVisible = false;
							});
					}
				});
			},
			showType(index) {
				this.dialogFormVisible = true;
				this.protocolTypeForm = this.protocolTypeList[index];
			},
			removeType(index) {
				console.log(index, this.protocolTypeList[index])
				var protocolType = this.protocolTypeList[index];
				this.$confirm('确定要删除协议类别[' + protocolType.name + '], 是否继续?', '提示', {
					conproductButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let url = this.$apis.material.replace("{serial}", protocolType.serial);
					this.$http.request(this, url, {}, true, res => {
						this.protocolTypeList.splice(index, 1);
					});
				});
			}
		},
		created() {
			this.getTypeList();
		}
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
